<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('AppUser.list.addNewUser') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- alert -->
          <b-alert
            variant="danger"
            :show="errors.length > 0"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              {{ errors }}
              <!-- <ul>
                <li v-for="item in errors" :key="item">
                  {{ item }}
                </li>
              </ul> -->
            </div>
          </b-alert>
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="full-name"
            rules="required"
          >
            <b-form-group
              :label="$t('AppUser.list.fullName')"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
                autocomplete="new-password"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="errors['RegisterNewUserCommand']"
                :state="errors['RegisterNewUserCommand'] ? false : true"
              >
                {{ errors['RegisterNewUserCommand'][0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            :name="$t('AppUser.list.username')"
            rules="required|alpha-num|min:2|max:150"
          >
            <b-form-group
              :label="$t('AppUser.list.username')"
              label-for="real-username"
            >
              <b-form-input
                id="real-username"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
                autocomplete="new-password"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required|email"
          >
            <b-form-group
              :label="$t('AppUser.list.email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
                autocomplete="new-password"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone Number -->
          <validation-provider
            #default="validationContext"
            :name="$t('AppUser.list.phoneNumber')"
            rules="required|phoneNumber|min:10|max:18"
          >
            <b-form-group
              :label="$t('AppUser.list.phoneNumber')"
              label-for="phone-number"
            >
              <b-form-input
                id="phone-number"
                v-model="userData.phoneNumber"
                :state="getValidationState(validationContext)"
                trim
                autocomplete="new-password"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-invalid-feedback
              v-if="errors['PhoneNumber']"
              :state="errors['PhoneNumber'] ? false : true"
            >
              {{ errors['PhoneNumber'][0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required|password"
          >
            <b-form-group
              :label="$t('AppUser.list.password')"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="userData.password"
                :state="getValidationState(validationContext)"
                trim
                type="password"
                autocomplete="new-password"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Confirm Password -->
          <validation-provider
            #default="validationContext"
            name="confirm-password"
            rules="required|confirmed:Password"
          >
            <b-form-group
              :label="$t('AppUser.list.password')"
              label-for="confirm-password"
            >
              <b-form-input
                id="confirm-password"
                v-model="userData.confirmPassword"
                :state="getValidationState(validationContext)"
                trim
                type="password"
                autocomplete="new-password"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-invalid-feedback
            v-if="errors['New User']"
            :state="errors['New User'] ? false : true"
          >
            {{ errors['New User'][0] }}
          </b-form-invalid-feedback>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="addingUser"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner
                v-if="addingUser"
                small
                class="mr-1"
              />
              {{ $t('Lists.Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="addingUser"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Lists.Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onMounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import useradminService from '@/services/useradmin.service'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
    }

    const errors = ref({})
    const addingUser = ref(false)
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      createUser,
    } = useradminService()

    const onSubmit = () => {
      addingUser.value = true
      createUser(userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          addingUser.value = false
        })
        .catch(error => {
          if (error.status === 400) {
            errors.value = error.errors
          }
          addingUser.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    onMounted(() => {
      resetuserData()
      resetForm()
    })

    return {
      userData,
      onSubmit,
      errors,
      addingUser,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
