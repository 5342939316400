<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="() => { refUsersList.refreshData() }"
    />
    <list
      ref="refUsersList"
      sort-by="userName"
      refetch-records-name="collection"
      key-field="userName"
      report-name="Usuarios.xlsx"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchUsers"
      :delete-method="deleteUser"
      :fetch-report="fetchUsersExportExcel"
    >
      <template #cell(user)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.name)"
              variant="light-danger"
            />
          </template>
          <input
            type="text"
            class="font-weight-bold d-block text-nowrap text-primary"
            :value="data.item.name">
          <small class="text-muted">@{{ data.item.userName }}</small>
        </b-media>
      </template>
      <template #cell(phoneNumber)="data">
        <input
          type="text"
          v-mask="['(###) ###-####']"
          :value="data.item.phoneNumber"
          class="font-weight-bold d-block text-nowrap text-primary">
      </template>
    </list>
  </div>
</template>

<script>
import {
  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { mask } from 'v-mask'
import i18n from '@/libs/i18n'
import useradminService from '@/services/useradmin.service'
import generalDataService from '@/services/generalData.service'
import List from '@/components/List.vue'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    List,
    UserListAddNew,
    BMedia,
    BAvatar,
  },
  setup() {
    /* Services */
    const {
      fetchUsers,
      deleteUser,
    } = useradminService()
    const {
      fetchUsersExportExcel,
    } = generalDataService()
    const refUsersList = ref(null)
    const isAddNewUserSidebarActive = ref(false)
    const filters = ref([
    ])
    const tableColumns = [
      { label: i18n.t('AppUser.list.user'), key: 'user', sortable: false },
      { label: i18n.t('AppUser.list.email'), key: 'email', sortable: false },
      { label: i18n.t('AppUser.list.phoneNumber'), key: 'phoneNumber', sortable: false },
      { label: i18n.t('Lists.Actions'), key: 'actions' },
    ]

    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'write',
        aclResource: 'Usuarios',
        routeName: 'apps-users-edit',
        params: ['userName'],
        icon: 'EditIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Usuarios',
        params: ['userName'],
        icon: 'TrashIcon',
      },
    ])

    const actions = ref([
      {
        label: i18n.t('Lists.Add'),
        aclAction: 'create',
        aclResource: 'Usuarios',
        click: () => { isAddNewUserSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
      {
        name: 'downloadReport',
        aclAction: 'read',
        aclResource: 'Usuarios',
      },
    ])

    return {
      // UI
      refUsersList,
      isAddNewUserSidebarActive,
      avatarText,
      mask,
      // Config
      filters,
      tableColumns,
      tableActions,
      actions,
      // Services
      fetchUsers,
      deleteUser,
      fetchUsersExportExcel,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
